import BaseInstance from "../base.instance";
import {
  CONFIG_PERSEN_TUKIN_LIST,
  CONFIG_PERSEN_TUKIN_DETAIL,
  CONFIG_PERSEN_TUKIN_SAVE
} from "../constants";

const ConfigService = {
  getList(data) {
    return BaseInstance.post(CONFIG_PERSEN_TUKIN_LIST, data);
  },
  getDetail(id) {
    return BaseInstance.fetch(CONFIG_PERSEN_TUKIN_DETAIL, id);
  },
  save(data) {
    return BaseInstance.post(CONFIG_PERSEN_TUKIN_SAVE, data);
  }
};

export default ConfigService;
