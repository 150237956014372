<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <v-card id="card-custom" class="px-2 py-4 rounded-lg">
          <v-card-actions>
            <p class="headline-color px-2">Konfigurasi Persentase Tukin</p>
          </v-card-actions>
          <v-divider />
          <v-row class="mx-2">
            <v-col cols="12" xl="6" lg="6" md="6" sm="12" xs="12" class="px-0">
              <v-card-actions>
                <v-btn
                  text
                  class="text-capitalize caption headline-color"
                  color="primary"
                  @click="handleCreate"
                  >Tambah Persen Tukin<v-icon class="ml-2" color="primary"
                    >add_circle</v-icon
                  ></v-btn
                >
              </v-card-actions>
            </v-col>
          </v-row>
          <v-data-table
            id="table-custom"
            :headers="headers"
            :items="configures"
            :loading="loading"
            :options.sync="options"
            :server-items-length="pagination.totalItem"
            :footer-props="{
              'items-per-page-options': rowsPerPageItems
            }"
            loading-text="Loading... Please wait"
          >
            <template v-slot:[`item.date_start`]="{ item }">
              <span>{{ item.date_start | date }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn
                v-if="item.btn_edit"
                x-small
                color="#297BBF"
                class="mr-2 my-1 white--text"
                @click="handleEdit(item)"
                >Ubah</v-btn
              >
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="visible" persistent max-width="800">
      <v-card class="pa-5 rounded-lg" id="card-custom">
        <v-btn @click="visible = false" x-small icon class="float-right">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text class="subtitle-2 px-0 font-weight-regular">
          {{ isEdit ? "Ubah" : "Tambah" }} Persen Tukin
        </v-card-text>
        <v-divider class="mb-4" />
        <v-form
          id="form"
          ref="form"
          v-model="valid"
          lazy-validation
          @submit.prevent="save()"
        >
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Persen Kinerja</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="form.percent_performance"
                outlined
                dense
                :rules="[v => !!v || 'Persen Kinerja harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Persen Kehadiran</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="form.percent_attendance"
                outlined
                dense
                :rules="[v => !!v || 'Persen Kehadiran harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="py-1">
              <v-subheader class="caption headline-color font-weight-regular"
                >Tanggal Mulai</v-subheader
              >
            </v-col>
            <v-col cols="9" class="py-1">
              <v-text-field
                v-model="form.date_start"
                type="date"
                outlined
                dense
                :rules="[v => !!v || 'Tanggal mulai harus diisi']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions class="mt-3">
            <v-spacer />
            <v-btn
              small
              outlined
              color="#717171"
              class="px-12"
              @click="visible = false"
              >Batal</v-btn
            >
            <v-btn
              small
              color="#FBB005"
              class="px-12 subtitle-2 text-capitalize"
              :loading="formLoading"
              type="submit"
              :disabled="!valid"
              form="form"
              >Simpan</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ConfigService from "@/services/resources/config.service";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      visible: false,
      isEdit: false,
      valid: false,
      loading: false,
      loadingUnit: false,
      formLoading: false,
      statusList: ["ACTIVE", "INACTIVE"],
      units: [],
      search: null,
      status: "ACTIVE",
      headers: [
        {
          text: "Persentase Kinerja",
          value: "percent_performance",
          sortable: false,
          align: "center"
        },
        {
          text: "Persentase Kehadiran",
          value: "percent_attendance",
          sortable: false,
          align: "center"
        },
        {
          text: "Tanggal Mulai",
          value: "date_start",
          sortable: false,
          align: "center"
        },
        { text: "", value: "actions", sortable: false, align: "center" }
      ],
      configures: [],
      pagination: {
        page: 1,
        itemsPerPage: 10,
        totalItem: 0,
        pageCount: 0
      },
      options: {
        sortBy: ["id"],
        sortDesc: [false]
      },
      dayDiffTry: 0,
      dayDiff: 0,
      rowsPerPageItems: [10, 20, 30, 40],
      form: {
        id: null,
        percent_performance: null,
        percent_attendance: null,
        date_start: null
      }
    };
  },
  watch: {
    options: {
      handler() {
        this.fetchListDebounce(this.getListConfig);
      },
      deep: true
    },
    paginationProperty: function() {
      this.options.page = 1;
      this.fetchListDebounce(this.getListConfig);
    },
    visible(val) {
      if (!val) {
        this.$refs.form.reset();
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser"
    }),
    paginationProperty() {
      return [this.search, this.status].join();
    }
  },
  methods: {
    handleCreate() {
      this.visible = true;
      this.isEdit = false;
    },
    handleEdit(item) {
      this.visible = true;
      this.isEdit = true;
      this.getDetailConfig(item.id);
    },
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("action", this.isEdit ? 2 : 1);
        formData.append("id", this.form.id);
        formData.append("percent_performance", this.form.percent_performance);
        formData.append("percent_attendance", this.form.percent_attendance);
        formData.append("date_start", this.form.date_start);
        this.saveConfig(formData);
      }
    },
    // Service
    async getListConfig() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      let orderBy = [];
      sortBy.map((d, i) => {
        let sort = sortDesc[i] ? "desc" : "asc";
        orderBy = [...orderBy, d, sort];
        return d;
      });
      try {
        this.loading = true;
        await ConfigService.getList({
          filter: {
            search: this.search,
            status: this.status
          },
          orderBy,
          pageCurrent: page,
          dataPerPage: itemsPerPage
        })
          .then(response => {
            const { status, data } = response.data;
            const { list, pageTotal, countTotal } = data;
            if (status) {
              let configures = list;
              configures.map((data, index) => {
                data.no = itemsPerPage * (page - 1) + (index + 1);
                return data;
              });

              this.configures = configures;
              this.pagination.totalItem = parseInt(countTotal);
              this.pagination.pageCount = parseInt(pageTotal);
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async saveConfig(data) {
      try {
        this.formLoading = true;
        await ConfigService.save(data)
          .then(response => {
            const { status, message } = response.data;
            if (this.dayDiffTry >= this.dayDiff) {
              if (status) {
                this.getListConfig();
                this.visible = false;
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: message,
                  color: "success"
                });
              } else {
                this.$store.commit("snackbar/setSnack", {
                  show: true,
                  message: message,
                  color: "error"
                });
              }
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => {
            this.formLoading = false;
            this.dayDiffTry = this.dayDiffTry + 1;
          });
      } catch (err) {
        console.error(err);
      }
    },
    async getDetailConfig(id) {
      try {
        this.loading = true;
        await ConfigService.getDetail(id)
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              this.form.id = data.id;
              this.form.percent_performance = data.percent_performance;
              this.form.percent_attendance = data.percent_attendance;
              this.form.date_start = data.date_start;
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    }
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > thead {
    background-color: #fdfdfd;
  }
  .v-data-table__wrapper > table > thead > tr > th {
    font-size: 0.775rem;
    font-weight: 400;
  }
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.775rem;
  }
}
#card-custom .v-input {
  font-size: 0.775rem;
}
</style>
